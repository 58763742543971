import { Table as BaseTable, TableProps, Overrides } from 'baseui/table-semantic'
import { withOverrides } from './overrides'
import { ourColors } from './Colors'

const globalOverrides: Overrides = {
  Root: {
    style: {
      borderLeftStyle: 'none',
      borderRightStyle: 'none',
      borderBottomStyle: 'none',
      borderTopStyle: 'none'
    }
  },
  TableHeadCell: {
    style: {
      paddingTop: '10px',
      fontSize: '15px',
      paddingLeft: '22px',
      backgroundColor: ourColors.tableHeaderGrey,
      borderBottomStyle: 'none',
      whiteSpace: 'normal',
      '::before': {
        content: 'none'
      },
      '::after': {
        content: 'none'
      }
    }
  },
  TableBodyCell: {
    style: {
      paddingTop: '12px',
      paddingBottom: '7px',
      fontSize: '15px',
      paddingLeft: '22px',
      borderBottomColor: ourColors.interfaceGrey,
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid'
    }
  }
}

export const Table = withOverrides<TableProps>(BaseTable, globalOverrides)
