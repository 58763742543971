import React from 'react'
import { Layout, UserContext } from '../components/Layout'
import { Grid, Cell, ALIGNMENT } from 'baseui/layout-grid'
import { HeadingLevel, Heading as BaseHeading } from 'baseui/heading'
import { Heading } from '../components/Heading'
import { WindowLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { getQueryVariable, handleErrorFromAPI, loginRedirect } from '../utils'
import { getHazardData, HazardPagesResponse } from '../api/cms'
import { useStyletron } from 'baseui'
import { RichTextAsElement } from '../components/RichTextAsElement'
import { VimeoVideo } from '../components/VimeoVideo'
import { Panel, AccordionSection } from '../components/Accordion'
import { MainContent } from '../components/MainContent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { NavButton } from '../components/NavButton'
import { KnowledgeBox } from '../components/KnowledgeBox'
import { HorizontalLine } from '../components/Line'
import { LinkButton } from '../components/LinkButton'
import { ErrorNotification } from '../components/Notification'
import { Table } from '../components/Table'

interface HazardPageHeaderProps {
  pageTitle: string
  hazardId: string
}
const HazardPageHeader = ({ pageTitle, hazardId }: HazardPageHeaderProps) => {
  const [css] = useStyletron()
  return (
    <>
      <Grid align={ALIGNMENT.center}>
        <Cell span={[2.25, 3.5]}>
          <HeadingLevel>
            <Heading data-testid="pageTitle">{pageTitle}</Heading>
          </HeadingLevel>
        </Cell>
        <Cell span={1.5} skip={[0.25, 1]}>
          <div className={css({ display: 'flex', justifyContent: 'flex-end' })}>
            <NavButton
              buttonOverrides={{ BaseButton: { style: { marginTop: '0px', width: '122px' } } }}
              navItems={[
                {
                  label: 'Log issue with this hazard',
                  url: `/issues/create-issue?category=${pageTitle}&hazard_id=${hazardId}`
                }
              ]}
            >
              Actions
            </NavButton>
          </div>
        </Cell>
      </Grid>
      <Grid>
        <Cell span={[4, 6]}>
          <HorizontalLine />
        </Cell>
      </Grid>
    </>
  )
}

interface HazardAccordionSectionProps {
  assessTheRisks: string
  controlTheRisks: string
  nextSteps: string
  findOutMore: string
}

const HazardAccordionSection = ({
  assessTheRisks,
  controlTheRisks,
  nextSteps,
  findOutMore
}: HazardAccordionSectionProps) => {
  const accordionPanels: Array<JSX.Element> = []

  if (assessTheRisks) {
    accordionPanels.push(
      <Panel title="Assess the risks" key="assessTheRisks">
        <RichTextAsElement richText={assessTheRisks} depth={0} forAccordion={true} />
      </Panel>
    )
  }
  if (controlTheRisks) {
    accordionPanels.push(
      <Panel title="Control the risks" key="controlTheRisks">
        <RichTextAsElement richText={controlTheRisks} depth={0} forAccordion={true} />
      </Panel>
    )
  }
  if (nextSteps) {
    accordionPanels.push(
      <Panel title="Next steps" key="nextSteps">
        <RichTextAsElement richText={nextSteps} depth={0} forAccordion={true} />
      </Panel>
    )
  }
  if (findOutMore) {
    accordionPanels.push(
      <Panel
        title="Find out more"
        key="findOutMore"
        overrides={{
          PanelContainer: {
            style: {
              marginBottom: '0px'
            }
          }
        }}
      >
        <RichTextAsElement richText={findOutMore} depth={0} forAccordion={true} />
      </Panel>
    )
  }

  return <AccordionSection heading={'More information'}>{accordionPanels}</AccordionSection>
}

interface ActionButtonsProps {
  category: string
  hazardId: string
}

const ActionButtons = (props: ActionButtonsProps) => {
  const [css] = useStyletron()
  return (
    <>
      <Grid>
        <Cell span={[4, 6]}>
          <HeadingLevel>
            <BaseHeading
              styleLevel={3}
              overrides={{
                Block: {
                  style: {
                    marginTop: '30px',
                    marginBottom: '0px'
                  }
                }
              }}
            >
              Actions
            </BaseHeading>
          </HeadingLevel>
        </Cell>
      </Grid>
      <Grid>
        <Cell span={[4, 6]}>
          <LinkButton
            className={css({
              marginTop: '14px',
              marginBottom: '24px',
              maxWidth: '345px',
              width: '100%',
              display: 'inline-block'
            })}
            data-testid="log-issue-button"
            to={`/issues/create-issue?category=${props.category}&hazard_id=${props.hazardId}`}
          >
            Log issue with this hazard
            <FontAwesomeIcon className={css({ marginLeft: '12px' })} icon={faPencilAlt} />
          </LinkButton>
        </Cell>
      </Grid>
    </>
  )
}

interface HazardPageProps {
  location: WindowLocation
}
export const HazardPage = (props: HazardPageProps) => {
  const { token, role, setUserContext } = React.useContext(UserContext)
  const [pageTitle, setPageTitle] = React.useState('')
  const [mainText, setMainText] = React.useState('')
  const [table, setTable] = React.useState<string[][] | null>(null)
  const [knowText, setKnowText] = React.useState('')
  const [assessTheRisks, setAssessTheRisks] = React.useState('')
  const [controlTheRisks, setControlTheRisks] = React.useState('')
  const [nextSteps, setNextSteps] = React.useState('')
  const [findOutMore, setFindOutMore] = React.useState('')
  const [video, setVideo] = React.useState('')
  const [loadingErrorActive, setLoadingErrorActive] = React.useState(false)
  const hazardId = getQueryVariable('id', props.location.search)

  React.useEffect(() => {
    if (!token) {
      loginRedirect()
    }
    if (!hazardId) {
      navigate('/404', { replace: true })
    }

    if (token && hazardId && role) {
      getHazardData(token, hazardId)
        .then((response: HazardPagesResponse) => {
          if (response.meta.total_count > 0) {
            const content = response.items[0]
            setPageTitle(content.title)
            if (role === 'HSR') {
              setVideo(content.hsr_video)
              setMainText(content.hsr_main_text)
              setTable(content.hsr_table?.[0]?.value?.data)
              setKnowText(content.hsr_know_box)
              setAssessTheRisks(content.hsr_assess_the_risks)
              setControlTheRisks(content.hsr_control_the_risks)
              setNextSteps(content.hsr_next_steps)
              setFindOutMore(content.hsr_find_out_more)
            } else if (role === 'Organiser') {
              setVideo(content.org_video)
              setMainText(content.org_main_text)
              setTable(content.org_table?.[0]?.value?.data)
              setKnowText(content.org_know_box)
              setAssessTheRisks(content.org_assess_the_risks)
              setControlTheRisks(content.org_control_the_risks)
              setNextSteps(content.org_next_steps)
              setFindOutMore(content.org_find_out_more)
            }
          } else {
            navigate('/404', { replace: true })
          }
        })
        .catch(error => handleErrorFromAPI(error, setUserContext, setLoadingErrorActive))
    }
  }, [token, hazardId, role, setUserContext])

  return (
    <>
      <Grid>
        <Cell span={[4, 6]}>
          <ErrorNotification hasError={loadingErrorActive} />
        </Cell>
      </Grid>
      {hazardId && pageTitle && <HazardPageHeader pageTitle={pageTitle} hazardId={hazardId} />}
      {video && <VimeoVideo videoUrl={video} />}
      <MainContent richText={mainText} />
      <Grid>
        <Cell span={[4, 6]}>
          {table && <Table columns={table[0]} data={table.slice(1, table.length)} />}
        </Cell>
      </Grid>
      {knowText && role && <KnowledgeBox richText={knowText} role={role} />}
      {pageTitle && (
        <HazardAccordionSection
          assessTheRisks={assessTheRisks}
          controlTheRisks={controlTheRisks}
          nextSteps={nextSteps}
          findOutMore={findOutMore}
        />
      )}
      {hazardId && pageTitle && <ActionButtons category={pageTitle} hazardId={hazardId} />}
    </>
  )
}
interface LayoutHazardPageProps {
  location: WindowLocation
}
const LayoutHazardPage = (props: LayoutHazardPageProps) => {
  return (
    <Layout>
      <HazardPage location={props.location} />
    </Layout>
  )
}

export default LayoutHazardPage
